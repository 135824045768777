import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

const styles = theme => ({
  root: {
    backgroundColor: 'transparent'
    // boxShadow: 'none',
  }
})

const CustomSubmitButton = (() => {
  const isWidthDownMD = useMediaQuery('(max-width:599px)');
  return (
    <Button
      id="submit_button"
      variant="contained"
      color="primary"
      size="large"
      fullWidth={isWidthDownMD}
      type="submit"
      style={
        isWidthDownMD
          ? { padding: 16 }
          : {}
      }
    >
      Trimite mesajul
    </Button>
  )
})

export default (
  withStyles(styles)(
    class ContactForm extends React.Component {
      constructor(props) {
        super(props)
        this.submitForm = this.submitForm.bind(this)
        this.state = {
          status: ''
        }
      }

      submitForm(ev) {
        ev.preventDefault()
        const form = ev.target
        const data = new FormData(form)
        const xhr = new XMLHttpRequest()
        xhr.open(form.method, form.action)
        xhr.setRequestHeader('Accept', 'application/json')
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== XMLHttpRequest.DONE) return
          if (xhr.status === 200) {
            form.reset()
            this.setState({ status: 'SUCCESS' })
          } else {
            this.setState({ status: 'ERROR' })
          }
        }
        xhr.send(data)
      }

      render() {
        const { classes } = this.props
        const { status } = this.state

        return (
          <Paper className={classes.root} elevation={0}>
            <Typography
              variant="h4"
              gutterBottom
              style={{
                fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif'
              }}
            >
              Contactează-ne!
            </Typography>
            <form
              onSubmit={this.submitForm}
              action="https://formspree.io/xleajrje"
              method="POST"
            >
              <input
                name="Tip"
                type="hidden"
                value="Formular de contact MSciTeh 2021"
              />
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <TextField
                    id="name"
                    name="Name"
                    type="text"
                    label="Nume și prenume"
                    size="small"
                    required={true}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    id="email"
                    name="Email"
                    type="email"
                    label="Adresă email"
                    size="small"
                    required={true}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="message"
                    name="Mesaj"
                    label="Mesaj"
                    multiline
                    rows="4"
                    size="small"
                    required={true}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  {status === 'SUCCESS' ? (
                    <Paper
                      elevation={4}
                      style={{
                        backgroundColor: 'green',
                        color: 'white',
                        padding: 24,
                        textAlign: 'center'
                      }}
                    >
                      Mulțumim pentru mesaj!
                    </Paper>
                  ) : (
                    <CustomSubmitButton />
                  )}
                  {status === 'ERROR' && (
                    <Paper
                      elevation={4}
                      style={{
                        backgroundColor: '#B22222',
                        color: 'white',
                        padding: 24,
                        textAlign: 'center'
                      }}
                    >
                      Ooops! A apărut o eroare.
                    </Paper>
                  )}
                </Grid>
              </Grid>
            </form>
          </Paper>
        )
      }
    }
  )
)
