import React from 'react'
import PropTypes from 'prop-types'
import { withGoogleSheets } from '../hooks/custom-react-db-google-sheets'

import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  //  Typography,
  Button,
  TextField,
  Autocomplete
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  autocomplete: {
    // borderBottom: `#ffffff55`,

    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiAutocomplete-inputRoot': {
      backgroundColor: '#ffffff55',

      '&:after': {
        borderBottomColor: theme.palette.secondary.main,
      },
    },
  },
  ROSEButton: {
    height: 56,
  },
}))

const ROSESchool = props => {
  const classes = useStyles()
  const { dataSource, title, buttons } = props
  const data = props.db[dataSource]
  // sheetSource = props.componentProps.data

  const [pendingValue, setPendingValue] = React.useState()

  // console.log(pendingValue)

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6} xl={7}>
        <Autocomplete
          options={data}
          groupBy={option => option.judet}
          getOptionLabel={option => option.denumire}
          // style={{ width: 300 }}
          renderInput={params => (
            <TextField {...params} label={title} />
          )}
          onChange={(event, value) => {
            setPendingValue(value && value.rose)
          }}
          className={classes.autocomplete}
        />
      </Grid>
      <Grid item xs={12} md={6} xl={5}>
        {buttons && pendingValue ? (
          <Button
            fullWidth
            href={buttons[pendingValue].link}
            variant="contained"
            size="large"
            color="primary"
            target="_blank"
            className={classes.ROSEButton}
          >
            {buttons[pendingValue].title}
          </Button>
        ) : (
          <Button
            fullWidth
            href={buttons[0].link}
            variant="contained"
            size="large"
            color="primary"
            target="_blank"
            className={classes.ROSEButton}
            disabled
          >
            {buttons[0].title}
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

ROSESchool.propTypes = {
  db: PropTypes.shape({
    ROSESchool: PropTypes.arrayOf(PropTypes.object),
  }),
}

export default withGoogleSheets('ScoliROSE')(ROSESchool)
