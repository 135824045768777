import React from 'react'
// import PropTypes from 'prop-types'

// import ReactMarkdown from 'react-markdown'

// import {
//   makeStyles,
//   // fade
// } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'

import ProgramCard from '../comps/ProgramCard'

// const useStyles = makeStyles((theme) => ({
//   heroContainer: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     marginBottom: theme.spacing(theme.shape.verticalSpacer),

//     [theme.breakpoints.up('sm')]: {
//       marginTop: theme.spacing(-4),
//     },
//     [theme.breakpoints.only('xs')]: {
//       marginTop: theme.spacing(-4.5),
//     },
//   },
//   heroImage: {
//     maxWidth: '100%',
//     margin: '0 auto',
//     // borderRadius: theme.shape.borderRadius
//   },
//   titleContainer: {
//     marginBottom: theme.spacing(theme.shape.verticalSpacer),
//   },
// }))

function BlogArticle({
  // config,
  dataSource,
  // siteLanguage,
  pageName
}) {
  // const classes = useStyles()
  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      ;(result[currentValue.day] = result[currentValue.day] || []).push(
        currentValue,
      )
      // console.log(result)
      return result
    }, {})
  }
  const group = groupBy(dataSource, 'day')
  // console.log('dataSource: ', dataSource)
  // console.log('grup: ', Object.keys(group))

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h3" component="h1">
          {pageName}
        </Typography>
      </Grid>
      {group &&
        Object.keys(group).map((key) => {
          return (
            <Grid item xs={12}>
              <Typography variant="h4" component="h2">
                {key}
              </Typography>
              <Grid container spacing={2}>
                {dataSource &&
                  dataSource.map((data, index) => {
                    // console.log(data)
                    if (JSON.parse(data.display) && data.day === key) {
                      // const domenii = data.keywords && data.keywords.split(',')
                      return (
                        <Grid item xs={12} key={index}>
                          <ProgramCard data={data} />
                        </Grid>
                      )
                    }
                    return null
                  })}
              </Grid>
            </Grid>
          )
        })}
    </Grid>
  )
}

BlogArticle.propTypes = {}

export default BlogArticle
