import React from 'react'

import {
  makeStyles,
  Container,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  alertInfo: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.info.main,
    textAlign: 'center',

    '&, &>.MuiAlert-icon': {
      color: theme.palette.info.contrastText,
    },
    '&>.MuiAlert-icon': {
      fontSize: 24,
    },
  },
}))

function useWidth() {
  const theme = useTheme()
  const keys = [...theme.breakpoints.keys].reverse()
  return (
    keys.reduce((output, key) => {
      const matches = useMediaQuery(theme.breakpoints.up(key))
      return !output && matches ? key : output
    }, null) || 'xs'
  )
}

const ShowBreakpoints = ({ show }) => {
  // const theme = useTheme()
  const classes = useStyles()
  const width = useWidth()

  if (show) {
    return (
      <Container maxWidth="lg" className={classes.mainContainer}>
        <Alert severity="info" className={classes.alertInfo}>
          <AlertTitle style={{ margin: 0 }}>
            Breakpoint:{' '}
            <strong style={{ textTransform: 'uppercase' }}>{width}</strong>
          </AlertTitle>
        </Alert>
      </Container>
    )
  } else {
    return null
  }
}

export default ShowBreakpoints
