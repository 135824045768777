import React from 'react'
import ReactDOM from 'react-dom'

import GoogleSheetsProvider from 'react-db-google-sheets'
import { CssBaseline, ThemeProvider } from '@material-ui/core'

import Loading from './hooks/custom-react-db-google-sheets/mui-progress'
import { MainTheme as theme } from './themes/MainTheme'
import App from './app'
import './styles.css'

const rootElement = document.getElementById('root')

const config = {
  dataLoading: {
    component: Loading,
  },
}

ReactDOM.render(
  <React.Fragment>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <GoogleSheetsProvider config={config}>
        <App />
      </GoogleSheetsProvider>
    </ThemeProvider>
  </React.Fragment>,
  rootElement,
)
